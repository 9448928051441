import { Component, Input, OnInit } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { combineLatestWith, startWith } from 'rxjs/operators';
import { TfaService } from '../../gk-user-settings/services/tfa/tfa.service';
import { SessionToolMode } from './services/session-tools/session-tools.model';
import { SessionToolsService } from './services/session-tools/session-tools.service';
import { TfaConfirmationService } from './services/tfa-confirmation/tfa-confirmation.service';
import { TfaResetService } from './services/tfa-reset/tfa-reset.service';

@Component({
  selector: 'gk-session-tools',
  templateUrl: './session-tools.component.html',
  styleUrls: ['./session-tools.component.scss'],
})
export class SessionToolsComponent implements OnInit {
  @Input() signUpLinkVisible = true;
  @Input() restorePasswordLinkVisible = true;
  @Input() externalLoginUrl: string;
  @Input() ewidLoginUrl: string;
  @Input() currentToolMode = SessionToolMode.Login;
  @Input() keepOpenLoginWindowWithModals: boolean;
  @Input() destroyOnForcedTfaActivation = true;
  @Input() isPzAuthenticationAvailable: boolean;
  @Input() isPerunAuthenticationAvailable: boolean;
  @Input() infoAlertsVisible: boolean;

  constructor(
    private tfaConfirmationService: TfaConfirmationService,
    public tfaResetService: TfaResetService,
    private sessionToolsService: SessionToolsService,
    public tfaService: TfaService,
  ) {}

  ngOnInit(): void {
    this.emitCurrentToolModeFromInput();
  }

  emitCurrentToolModeFromInput(): void {
    this.sessionToolsService.currentToolMode.next(this.currentToolMode);
  }

  isLoginMode(): Observable<boolean> {
    return this.sessionToolsService.currentToolMode.pipe(
      map((toolMode) => toolMode === SessionToolMode.Login),
    );
  }

  isModalWindow(): Observable<boolean> {
    return combineLatest([
      this.isTfaConfirmationMode(),
      this.isTfaResetMode(),
    ]).pipe(
      map(
        ([isTfaConfirmationMode, isTfaResetMode]) =>
          isTfaConfirmationMode || isTfaResetMode,
      ),
    );
  }

  isResetPasswordMode(): Observable<boolean> {
    return this.sessionToolsService.currentToolMode.pipe(
      map((toolMode) => toolMode === SessionToolMode.ResetPassword),
    );
  }

  isNewPasswordMode(): Observable<boolean> {
    return this.sessionToolsService.currentToolMode.pipe(
      map((toolMode) => toolMode === SessionToolMode.NewPassword),
    );
  }

  isTfaConfirmationMode(): Observable<boolean> {
    return this.tfaConfirmationService.tfaConfirmationInOperation.pipe(
      combineLatestWith(this.sessionToolsService.currentToolMode),
      map(
        ([tfaConfirmationInOperation, currentToolMode]) =>
          tfaConfirmationInOperation &&
          currentToolMode === SessionToolMode.TfaConfirmation,
      ),
      startWith(false),
    );
  }

  isTfaResetMode(): Observable<boolean> {
    return this.sessionToolsService.currentToolMode.pipe(
      map((toolMode) => toolMode === SessionToolMode.TfaReset),
    );
  }
}
